// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore, collection, getDocs } from 'firebase/firestore/lite';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCFuSR0P1z10VFuYcxcOztPec11azUXjCU",
  authDomain: "bhaiji-d63dc.firebaseapp.com",
  projectId: "bhaiji-d63dc",
  storageBucket: "bhaiji-d63dc.appspot.com",
  messagingSenderId: "577010740525",
  appId: "1:577010740525:web:4613c760096c007cd377a4",
  measurementId: "G-3DBN25TQ47"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);

export {db}

// Get a list of cities from your database
async function getTips(db) {
  const tipsCollection = collection(db, 'tips');
  const tipsSnapshot = await getDocs(tipsCollection);
  const tipsList = tipsSnapshot.docs.map(doc => doc.data());
  return tipsList;
}